<template>
  <div class="abilityDetails">
    <div class="content">
      <!-- 时间 -->
      <div class="time top">
        <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)" />
        <div class="current">{{ current }}</div>
        <svg-icon v-show="current != today" icon-class="back" class="arr arr-right" @click="getDate(true)" />
      </div>
      <!-- 计时 -->
      <div class="timing">
        <div class="num">{{ currentTime }}</div>
        <div class="number">
          {{ currentPinch }}/{{ currentStretch }}<span class="millimeter">毫米汞柱</span>
        </div>
      </div>
      <!-- 图表 -->
      <div class="charts">
        <div id="bloodecharts" class="bloodecharts"></div>
      </div>
      <!-- tips -->
      <div class="Tips">
        <div class="shrink">收缩压</div>
        <div class="diastole">舒张压</div>
      </div>
      <!-- button -->
      <div class="buttons">
        <div class="btn">
          <div class="average">平均值</div>
          <div class="num">{{ valuepinchAvg }}/{{ valuestretchAvg }}<span class="mercury">毫米汞柱</span></div>
        </div>
        <div class="btn btn1">
          <div class="pressure">压力范围</div>
          <div class="num">{{ valuepinchMax }}/{{ valuestretchMin }}<span class="mercury">毫米汞柱</span></div>
        </div>
      </div>
    </div>

    <div class="content">
      <!-- 时间 -->
      <div class="time">
        <div class="current">血压走势</div>
      </div>
      <!-- 图表 -->
      <div class="charts">
        <div id="historyBloodecharts" class="bloodecharts"></div>
        <!-- <div class="timenode">
              <div>00:00</div>
              <div>06:00</div>
              <div>12:00</div>
              <div>18:00</div>
              <div>24:00</div>
          </div> -->
      </div>
      <HistoryBar @onIndexCahnge="onIndexCahnge"></HistoryBar>
      <!-- tips -->
      <div class="Tips">
        <div class="shrink">收缩压</div>
        <div class="diastole">舒张压</div>
      </div>
      <!-- button -->
      <div class="buttons">
        <div class="btn">
          <div class="average">最低值</div>
          <div class="num">{{ historyPinchMin }}/{{ historystretchMin }}<span class="mercury">毫米汞柱</span></div>
        </div>
        <div class="btn btn1">
          <div class="pressure">最高值</div>
          <div class="num">{{ historyPinchMax }}/{{ historystretchMax }}<span class="mercury">毫米汞柱</span></div>
        </div>
      </div>
    </div>

</div>
</template>

<script>

import { current } from '@/util/validate'
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import { GridComponent, LegendComponent, TooltipComponent } from 'echarts/components'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate } from '@/api/index'
import { mapGetters } from 'vuex'
import HistoryBar from '../../components/tabbar/HistoryBar.vue'

// 注册必须的组件
echarts.use([GridComponent, CanvasRenderer, LineChart, LegendComponent, TooltipComponent])
export default {
  name: 'Blood',
  components: {
    HistoryBar
  },
  data() {
    return {
      current: current(),
      today: current(),
      valuepinchAvg: null, // 平均值-收压
      valuepinchMax: null, // 压力范围-最大值
      valuestretchAvg: null, // 平均值-舒压
      valuestretchMin: null, // 压力范围-最小值
      xData: [],
      stretchData: [], // 舒压
      pinchData: [], // 收压
      currentStretch: null,
      currentPinch: null,
      currentTime: null,
      historyXData: [],
      historyStretchData: [],//历史舒压
      historyPinchData: [],//历史收压
      historyPinchMax: 0,//历史收压最大值
      historyPinchMin: 0,//历史收压最小值
      historystretchMax: 0,//历史舒压最大值
      historystretchMin: 0,//历史舒压最小值
      currentActiveIndex: 0,
    }
  },
  mounted() {
    this.getBloodData()
    this.getHistoryBloodData()
  },
  methods: {
    getBloodData() {
      GetWatchTypeByDate({
        // userId: this.userid,
        userId:'Mini_44c7f5ff-52a0-48c6-8c64-592c9f6fc5e9',
        time: this.current,
        type: 2// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        const _data = res.data
        this.valuepinchAvg = _data.valuepinchAvg
        this.valuepinchMax = _data.valuepinchMax
        this.valuestretchAvg = _data.valuestretchAvg
        this.valuestretchMin = _data.valuestretchMin
        this.xData = _data.timelist[0].time
        this.stretchData = _data.timelist[0].stretch
        this.pinchData = _data.timelist[0].pinchvalue
        this.stretchData.forEach((v, i) => {
          if (v != 0) {
            this.currentStretch = v,
              this.currentTime = this.xData[i]
            this.currentPinch = this.pinchData[i]
          }
        })
        this.$nextTick(() => {
          this.bloodConfig()
        })
      }).catch(err => {
        this.xData = []
        this.stretchData = []
        this.pinchData = []
        this.$nextTick(() => {
          this.bloodConfig()
        })
      })
    },
    bloodConfig() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('bloodecharts')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        grid: {
          left: '3%',
          right: '7%',
          bottom: '3%',
          containLabel: true
        },
        color: ['#9566FF', '#FFC3B8'],
        legend: {
          data: ['Email', 'Union Ads']
        },
        tooltip: {
          trigger: 'axis',
          formatter: (value) => {
            console.log(value,"value")
            let xIndex = value[0].dataIndex
            this.currentStretch = this.stretchData[xIndex]
            this.currentPinch = this.pinchData[xIndex]
            this.currentTime = this.xData[xIndex]
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.xData,
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              interval: Math.ceil(this.xData.length / 4),
              margin: 20
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: 200,
            min: 0,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            max: 300,
            min: 0,
            splitNumber: 5,
          }
        ],
        series: [
          // 第一条线
          {
            name: '舒张压',
            type: 'line',
            showSymbol: false, // 小圆点是否显示
            emphasis: {
              focus: 'series'
            },
            data: this.stretchData,
            symbolSize: 0, // 拐点的颜色
            areaStyle: {
              // 区域样式
              type: 'default',
              opacity: 0.3
            },
            itemStyle: {
              // 图形区域颜色
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(149,102,255,0.6)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(149,102,255,1)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              },
              normal: {
                label: {
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value
                    } else {
                      return ''
                    }
                  }
                }
              }
            }
          },
          // 第二条线
          {
            name: '收缩压',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            color: '#FFC1B5',
            areaStyle: {
              // 区域样式
              type: 'default',
              opacity: 0.2
            },
            stack: null,
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,146,129,0.9)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,146,129,1)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              },
              normal: {
                label: {
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value
                    } else {
                      return ''
                    }
                  }
                }
              }
            },
            data: this.pinchData
          }
        ]
      }
      option && myChart.setOption(option)
    },
    getHistoryBloodData() {
      GetWatchTypeByHistoryDate({
        // userId: this.userid,
        userId:'Mini_44c7f5ff-52a0-48c6-8c64-592c9f6fc5e9',
        timeType: this.currentActiveIndex + 1,//1近一周 2近一个月 3近三个月 4近半年 5近一年
        dateTtpe: 2// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        const _data = res.data
        console.log(_data)
        this.historyPinchMax = _data.pinchMax//历史收压最大值
        this.historyPinchMin = _data.pinchMin//历史收压最小值
        this.historystretchMax = _data.stretchhMax//历史舒压最大值
        this.historystretchMin = _data.stretchMin//历史舒压最小值
        let _list = _data.daysList.reverse()
        this.historyXData = _list.map(item => {
          return item.time;
        })
        this.historyPinchData = _list.map(item => {
          return item.value;
        })
        this.historyStretchData = _list.map(item => {
          return item.value1;
        })
        this.$nextTick(() => {
          this.historyBloodConfig()
        })
      })
    },
    historyBloodConfig() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('historyBloodecharts')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        grid: {
          left: '3%',
          right: '7%',
          bottom: '3%',
          containLabel: true
        },
        color: ['#9566FF', '#FFC3B8'],
        legend: {
          data: ['Email', 'Union Ads']
        },
        tooltip: {
          trigger: 'axis',
          formatter: (value) => {
            // let xIndex = value[0].dataIndex
            // this.currentStretch = this.stretchData[xIndex]
            // this.currentPinch = this.pinchData[xIndex]
            // this.currentTime = this.xData[xIndex]
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.historyXData,
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              // interval: Math.ceil(this.xData.length / 4),
              margin: 20,
              formatter: (v, i) => {
                return this.currentActiveIndex == 0 || this.currentActiveIndex == 1 ? v.substring(5) : v
              },
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: 200,
            min: 0,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          // 第一条线
          {
            name: '舒张压',
            type: 'line',
            showSymbol: false, // 小圆点是否显示
            emphasis: {
              focus: 'series'
            },
            data: this.historyStretchData,
            symbolSize: 0, // 拐点的颜色
            areaStyle: {
              // 区域样式
              type: 'default',
              opacity: 0.3
            },
            itemStyle: {
              // 图形区域颜色
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(149,102,255,0.6)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(149,102,255,1)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              },

            }
          },
          // 第二条线
          {
            name: '收缩压',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            color: '#FFC1B5',
            areaStyle: {
              // 区域样式
              type: 'default',
              opacity: 0.2
            },
            stack: null,
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,146,129,0.9)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,146,129,1)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              },
              normal: {
                label: {
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value
                    } else {
                      return ''
                    }
                  }
                }
              }
            },
            data: this.historyPinchData
          }
        ]
      }
      option && myChart.setOption(option)
    },
    onIndexCahnge(index) {
      this.currentActiveIndex = index
      this.getHistoryBloodData()
    },
    getDate(flag) {
      let t = flag ? new Date(this.current).getTime() + 86400000 : new Date(this.current).getTime() - 86400000
      this.current = current(new Date(t))
      this.getBloodData()
    },
  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>

<style lang="scss" scoped>
.abilityDetails {
  height: 100%;
  padding: 0 20px;
  background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/modularBag5.png') no-repeat;
  background-size: 100%;
  background-color: #f7f7f7;

  .content {
    width: 100%;
    border-radius: 15px;
    background: #fff;
    // height: 80%;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    .time {
      color: #868686;
      display: flex;
      align-items: center;
      font-size: 16px;
      position: relative;

      .arr-left {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: -10px;
      }

      .arr-right {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) rotate(180deg);
        right: -10px;
      }

      &.top {
        margin-bottom: 20px;
        display: flex;
        align-content: center;
      }

      .current {
        font-size: 20px;
        font-weight: 700;
        margin: 0 10px;
      }
    }

    .timing {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #868686;
      font-size: 28px;
      margin: 20px 0;

      .num {
        color: #868686;
        font-size: 30px;
      }

      .number {
        color: #282828;
        margin-top: 15px;
      }

      .millimeter {
        font-size: 20px;
        color: #868686;
      }
    }

    .charts {
      position: relative;
      width: 100%;

      .bloodecharts {
        // width: 300px;
        width: 100%;
        height: 240px;
      }

      .timenode {
        width: 90%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: -20px;
        right: 0;
        color: #808080;
      }
    }

    /deep/ .history-tabbar {
      margin-bottom: 0;
    }

    .Tips {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      color: #4f4f4f;
      // color:#a990e5
      font-size: 14px;
      margin-top: 20px;

      .shrink,
      .diastole {
        position: relative;
      }

      .shrink::before,
      .diastole::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 5px;
      }

      .shrink::before {
        background: #db7d2b;
      }

      .diastole::before {
        background: #9566ff;
      }
    }

    .buttons {
      display: flex;
      margin-top: 30px;

      .btn {
        width: 155px;
        height: 65px;
        border: 1px solid #9566ff;
        background: #f3eeff;
        border-radius: 8px;

        .average,
        .pressure {
          position: relative;
          font-size: 5px;
          color: #858585;
          padding: 5px 20px;
        }

        .average::before,
        .pressure::before {
          content: "";
          width: 5px;
          height: 11px;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 5px;
        }

        .average::before {
          background: #9566ff;
        }

        .pressure::before {
          background: #db7d2b;
        }

        .num {
          font-size: 20px;
          // margin: 8px 5px 5px 30px;
          padding: 8px 5px 5px 5px;
          text-align: center;
        }
      }

      .btn1 {
        margin-left: 5px;
        background-color: #fff0ee;
        border: 1px solid #ff9180;
      }

      .mercury {
        font-size: 10px;
        color: #858585;
      }
    }
  }
}</style>
